import { useState, useEffect, useRef } from "react";
import { Field } from "formik";
import cn from "classnames";

function Input({
  fullWidth,
  noMargin,
  type,
  fieldname,
  validator,
  secondary,
  label,
  maxLength,
  style,
  value,
  disabled,
  readOnly,
  className,
  placeholder,
  scrollOnFocus = true,
  outerLabel,
  onChangeEvent,
  onBlurEvent,
  autoFocus,
  showDisplayLabel = false
}) {
  const inputWrapper = useRef();
  const [displayLabel, setDisplayLabel] = useState(showDisplayLabel || !value);
  const applyValidation = x => {
    let msg;
    if (type === "email") {
      msg = "Please enter a valid email address";
    } else if (type === "password") {
      msg = "Please complete password fields";
    }
    x.target.setCustomValidity(msg);
  };

  const customSetCustomValidity = e => {
    e.target.setCustomValidity("");
    if (!outerLabel) {
      e.target.value !== "" ? setDisplayLabel(false) : setDisplayLabel(true);
    }
  };

  const scrollIntoViewOnFocus = () => {
    if (inputWrapper) {
      const offset = inputWrapper.current.getBoundingClientRect().top;
      setTimeout(() => {
        window.scrollTo(0, offset);
      }, 200);
    }
  };

  const handleChange = e => {
    if (onChangeEvent) {
      onChangeEvent(fieldname, e.target.value);
    }
  };

  const handleBlur = () => {
    if (onBlurEvent) {
      onBlurEvent(fieldname, true);
    }
  };

  return (
    <div
      ref={inputWrapper}
      onClick={scrollOnFocus ? scrollIntoViewOnFocus : null}
      className={cn("input__wrapper", { "outer-label": outerLabel })}
    >
      <Field
        disabled={disabled}
        type={type ? type : "text"}
        name={fieldname}
        id={fieldname}
        validate={validator ? validator : false}
        onInvalid={applyValidation}
        onInput={customSetCustomValidity}
        readOnly={readOnly}
        value={value}
        style={style}
        placeholder={placeholder || ""}
        className={className}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus={autoFocus}
      />
      <label className="input__label" htmlFor={fieldname} style={{ opacity: displayLabel ? "1" : "0" }}>
        {label}
      </label>
      <style jsx global>{`
        input {
          background-color: ${secondary ? "#F6F9FF" : "#fff"};
          padding: 16px 40px !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          line-height: 1.63;
          transition: color 200ms ease-in, border-color 200ms ease-in !important;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        input::placeholder {
          font-size: 16px;
          font-weight: 600 !important;
          line-height: 1.63;
          letter-spacing: normal;
          opacity: 1;
          color: #818181;
        }

        input[disabled] {
          opacity: 0.5;
          filter: grayscale(1);
        }

        input:not([type="checkbox"]):active,
        input:not([type="checkbox"]):focus {
          ${readOnly ? "border-color: none !important;" : ""};
        }

        .input__wrapper {
          position: relative;
          width: 100%;
          max-width: ${fullWidth ? "100%;" : "388px;"};
          margin: 0 auto;
          margin-bottom: ${noMargin ? "0;" : "14px;"};
          ${readOnly ? "pointer-events: none;" : ""};
        }

        .input__wrapper.outer-label {
          margin-bottom: 0;
          margin-top: 2em;
        }

        .input__wrapper.outer-label label {
          position: absolute;
          top: -25%;
        }

        .input__wrapper.outer-label {
          margin-bottom: 0;
          margin-top: 2em;
        }

        .input__wrapper.outer-label label {
          position: absolute;
          top: -25%;
        }

        @media (max-width: 800px) {
          input {
            padding-left: 30px !important;
            padding-right: 25px !important;
          }
          .input__wrapper {
            max-width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default Input;
