import ArrowIcon from "./Icons/ArrowIcon";
import FacebookIcon from "./Icons/FacebookIcon";
import TwitterIcon from "./Icons/TwitterIcon";
import LinkedinIcon from "./Icons/LinkedinIcon";

const shareIcon = icon => {
  if (icon === "facebook") return <FacebookIcon />;
  if (icon === "twitter") return <TwitterIcon />;
  if (icon === "linkedin") return <LinkedinIcon />;
};

export default function Button({
  type,
  onClick,
  share,
  disabled,
  children,
  maxWidth,
  arrow,
  transparent,
  primary,
  style
}) {
  return (
    <button
      type={type || "submit"}
      onClick={onClick}
      className={share ? "share" : ""}
      disabled={disabled}
      style={style}
    >
      {share && shareIcon(share)}
      {children}
      {arrow && <ArrowIcon />}
      <style jsx>{`
        /* Reset */
        button {
          border: none;
          margin: 0;
          padding: 0;
          width: 100%;
          overflow: visible;
          background: transparent;
          color: inherit;
          font: inherit;
          line-height: normal;
          cursor: pointer;
          transition: background-color 200ms ease-in-out,
            box-shadow 200ms ease-in-out;
          user-select: none;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: ${maxWidth ? maxWidth : "388px"};
          font-size: 16px;
          font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          font-weight: bold;
          color: ${primary ? "#fff" : "#007aff"};
          background-color: ${transparent
            ? "transparent"
            : primary
            ? "#007aff"
            : "#fff"};
          border: ${primary ? "none" : "1px solid #007aff"};
          padding: 1.15em 0;
          border-radius: 3px;
          margin: 14px auto;
        }

        button.share {
          margin: 3em auto;
          margin-bottom: 1.5em;
        }

        button[disabled] {
          cursor: not-allowed;
          filter: grayscale(1);
          opacity: 0.4;
        }

        button:not([disabled]):hover {
          background-color: ${transparent
            ? "transparent"
            : primary
            ? "#0073f2"
            : "#f2f2f2"};
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
        }

        button:not([disabled]):active {
          background-color: ${transparent
            ? "transparent"
            : primary
            ? "#006de5"
            : "#e5e5e5"};
        }

        @media (max-width: 800px) {
          button {
            font-size: 14px;
            max-width: 100%;
          }
        }
      `}</style>
    </button>
  );
}
