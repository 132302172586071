export default function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      viewBox="0 0 23 19"
    >
      <path
        fill="#2479FF"
        fillRule="nonzero"
        d="M19.94 4.539c.008.198.013.398.013.598 0 6.083-4.583 13.095-12.967 13.095-2.574 0-4.97-.76-6.986-2.067a9.089 9.089 0 0 0 6.747-1.906c-1.994-.037-3.677-1.368-4.257-3.197a4.55 4.55 0 0 0 2.058-.078C2.463 10.562.892 8.702.892 6.471l.001-.058a4.504 4.504 0 0 0 2.064.575A4.612 4.612 0 0 1 .93 3.158c0-.845.224-1.636.616-2.316 2.247 2.786 5.606 4.618 9.393 4.81a4.642 4.642 0 0 1-.117-1.049c0-2.541 2.04-4.603 4.556-4.603 1.312 0 2.494.56 3.327 1.453A9.042 9.042 0 0 0 21.6.336a4.616 4.616 0 0 1-2.007 2.547 8.99 8.99 0 0 0 2.618-.724 9.219 9.219 0 0 1-2.271 2.38z"
      />
      <style jsx>{`
        svg {
          margin: 0 1rem;
        }
      `}</style>
    </svg>
  );
}
