import { Formik, Form } from 'formik';
import Link from 'next/link';
import React from 'react';
import { useDispatch } from 'react-redux';

import UserModel from '@commonenergy/js-lib-client/src/models/User';

import { init as initStore, signIn, signInWithEmailLink } from '../store/actions';

import useFirebase from '../hooks/useFirebase';

import Button from '../components/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '../components/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Separator from '../components/Separator';
import SingleStep from '../components/SingleStep';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export default function Index ({ next, resume }) {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [error, setError] = React.useState(false);
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ pageIsLoading, setPageIsLoading ] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleSubmit = React.useCallback(values => {
    setIsLoading(true);
    dispatch(signIn(values.emailAddress, values.password))
      .catch(error => {
        localStorage.setItem("email", values.emailAddress);
        setError(error);
      })
      .then(() => setIsLoading(false));
  }, [ dispatch ]);

  const handleClickTogglePasswordVisibility = React.useCallback(() => {
    setShowPassword(!showPassword);
  }, [ showPassword ]);

  React.useEffect(() => {
    const windowLocationHref = location.href;
    let email = localStorage.getItem("email") || false;

    if (firebase.doCheckIsSignInWithEmailLink(windowLocationHref)) {
      if (!email) {
        email = prompt("Please provide your email for confirmation");
      }
      dispatch(signInWithEmailLink(email, windowLocationHref))
        .then(user => {
          if (!user) console.warn(`No user with ${email} in the system.`);
        })
        .catch(error => {
          setError({ code: error.code, message: error.message });
        })
        .then(resume);
    }
    else {
      dispatch(initStore())
        .then(([ user ]) => !UserModel.exists(user) && setPageIsLoading(false));
    }
  }, []);

  return (
    <>
      <SingleStep first isFirst isLoading={pageIsLoading} prefix="Enter your email address to sign in or create an account">
        <Formik
          initialValues={{
            emailAddress: "",
            password: ""
          }}
          onSubmit={handleSubmit}>
          {props => (
            <Form>
              <Input
                placeholder="Email"
                fieldname="emailAddress"
                type="email"
                required
                autoFocus
                value={props.values.emailAddress}
                onChangeEvent={props.setFieldValue}
                onBlurEvent={props.setFieldTouched}
              />
              <div className="password">
                <Input
                  placeholder="Password"
                  fieldname="password"
                  type={showPassword ? "text" : "password"}
                  required
                  value={props.values.password}
                  onChangeEvent={props.setFieldValue}
                  onBlurEvent={props.setFieldTouched}
                />
                <div className="password-visibility-controls">
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickTogglePasswordVisibility}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                </div>
              </div>
              {error.message ? <p className="error">{error.message}</p> : null}

              <Button
                primary
                disabled={
                  !!!props.values.emailAddress ||
                  !!!props.values.password ||
                  isLoading
                }
                onClick={() => {
                  setError({
                    code: false,
                    message: ""
                  });
                }}
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
        <div className="link">
          <a href="/forgot-password" className="cta">
            Forgot password?
          </a>
        </div>
        <Separator text="I don't have an account" />
        <Button primary onClick={() => next()}>Sign Up</Button>
      </SingleStep>
      <style jsx>{`
        .error {
          height: 65px;
          margin: 0;
          padding: 1em 0;
          text-align: center;
        }
        .link {
          display: flex;
          justify-content: center;
          margin: 25px 0;
        }
        .password {
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
        }
        @media only screen and (max-width: 599px) {
          .password-visibility-controls {
            top: 30px;
            left: 80% !important;
            position: absolute;
          }
        }
        @media (min-width: 600px) {
          .password-visibility-controls {
            top: 30px;
            left: 67%;
            position: absolute;
          }
        }
      `}</style>
    </>
  );
}
